@tailwind base;
@tailwind components;
@tailwind utilities;

* {
   font-family: "Helvetica Neue", Helvetica, "HelveticaNeue-Light",
      "Helvetica Neue Light", Arial, "Lucida Grande", sans-serif;
}

html {
   font-size: 62.5%;
   scroll-behavior: smooth;
}

body {
   padding: 0px;
   margin: 0px;
   overflow-x: hidden;
   color: #000000a6 !important;
   position: relative;
}

.ant-tabs {
   min-height: 100%;
}

.ant-tabs .ant-tabs-nav {
   border-bottom: 1px solid #1a2241;
}

.row-dragging {
   background: #fafafa;
   border: 1px solid #ccc;
}

.row-dragging td {
   height: 100%;
   padding: 16px;
}

.row-dragging .drag-visible {
   visibility: visible;
}

.row-dragging .drag-hiden {
   visibility: hidden;
   opacity: 0;
}

::-webkit-scrollbar {
   width: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   margin: 0;
}

ul {
   margin: 0;
}

a {
   color: #000000a6 !important;
}

p {
   margin: 0;
}

h1 {
   font-size: 2.8rem;
}

h2 {
   font-size: 2.4rem;
}

h3 {
   font-size: 2rem;
}

h4 {
   font-size: 1.6rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type="number"] {
   -moz-appearance: textfield;
}

@keyframes fadeIn {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}

.fade-in {
   animation: fadeIn 0.3s ease-in;
}
