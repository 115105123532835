.ant-input {
   &:disabled {
      color: #000000d9 !important;
   }
}

.ant-select-disabled {
   .ant-select-selector {
      color: #000000d9 !important;
   }
}

.ant-message-custom-content {
   display: flex;
   align-items: center;
}

.ant-menu {
   color: #000000a6 !important;
}

.ant-menu-item {
   margin-top: 0px !important;
}

.ant-dropdown-menu-submenu {
   .ant-dropdown-menu-submenu-title {
      color: #000000a6 !important;
   }
}

// sidebar
.wrapper-sidebar-menu {
   max-height: calc(100vh - 48px);
   overflow: auto;
   background-color: #1a2241 !important;
   .ant-menu-dark,
   .ant-layout-sider {
      background-color: #1a2241 !important;
   }
   .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #1a2241;
      button {
         display: none;
      }
      @media screen and (max-width: 426px) {
         justify-content: space-between;
         padding: 0 16px;
         button {
            display: inline-block;
         }
      }
      svg {
         color: white;
         font-size: 23px;
      }
      a {
         color: white !important;
         font-size: 22px;
         text-shadow: 2px 2px 4px #0000003b;
      }
   }
   a {
      color: white !important;
   }
   .ant-menu-sub {
      background-color: #1a2241 !important;
   }
   .active-menu {
      background-color: #283156;
      &:hover {
         background-color: #283156;
      }
   }
   .ant-menu-sub {
      background-color: #1a2241 !important;
   }
   .active-menu .active-sub-menu {
      background-color: #283156;
   }
   .active-menu > a {
      color: #fff !important;
   }
   @media screen and (max-width: 426px) {
      position: fixed !important;
      z-index: 2;
      width: 100vw !important;
      min-width: 100vw !important;
      max-width: 100vw !important;
      height: 100vh;
      max-height: calc(100vh);
      &.ant-layout-sider-collapsed {
         width: 0 !important;
         min-width: 0 !important;
      }
   }
   .ant-menu-title-content {
      color: white;
   }
}

.site-layout {
   display: flex;
   height: 100vh;
   min-height: 100vh;
   @media screen and (max-width: 426px) {
      height: var(--window-height);
      max-height: var(--window-height);
      min-height: var(--window-height);
   }
   main {
      flex-grow: 1;
      overflow: scroll;
   }
}
